import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import moment from 'moment'
import SEO from '../components/SEO'

import PhotoList from '../components/PhotoList'

const PageTitle = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  h1 {
    position: relative;
    text-align: center;
    margin: 2rem 0;
    padding: 0 1.5rem;
    color: #fff;
    background-color: var(--primary-color-dark);
  }
`

const Date = styled.p`
  text-align: center;
  color: var(--rust);
  margin: 0 0 3rem 0;
  font-family: var(--numbers-font-family);
  font-size: 3rem;
  line-height: normal;
  &::before {
    content: '•';
    font-size: 2.4rem;
    margin-right: 0.6rem;
  }
  &::after {
    content: '•';
    font-size: 2.4rem;
    margin-left: 0.6rem;
  }
`

const Line = styled.div`
  border-bottom: 2px dotted var(--rust);
  width: 100%;
  position: absolute;
  margin-top: 0.4em;
`

const PageDescription = styled.p`
  text-align: center;
  padding: 0 1.5rem;
  max-width: 800px;
  margin: 3rem auto 3rem auto;
`

export default function SingleProjectPage({ data: { project, photos } }) {
  return (
    <>
      <SEO
        title={project.name}
        image={project.cover?.image?.asset?.fluid?.src}
      />
      <PageTitle>
        <Line />
        <h1>{project.name}</h1>
      </PageTitle>
      {/* {project.date && (
        <Date className="date">
          {moment(project.date, 'YYYY-MM-DD').format('YYYY')}
        </Date>
      )} */}
      {project.description && (
        <PageDescription>{project.description}</PageDescription>
      )}
      <PhotoList photos={photos.nodes} />
    </>
  )
}

// This needs to be dynamic based on the slug passed in via context in gatsby-node.js
export const query = graphql`
  query($slug: String!) {
    project: sanityProject(slug: { current: { eq: $slug } }) {
      name
      description
      date
      cover {
        image {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    photos: allSanityPhoto(
      filter: { project: { slug: { current: { eq: $slug } } } }
      sort: { fields: priority, order: DESC }
    ) {
      nodes {
        id
        name
        image {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
        date
        category {
          title
        }
        project {
          name
          slug {
            current
          }
        }
        tags {
          name
          slug {
            current
          }
        }
      }
    }
  }
`
